<template>
  <div class="loading-screen" :class="{ slide: completed }">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-6 col-md-4 col-lg-3">
          <div class="earth">
            <Lottie :options="earthOptions" v-on:animCreated="handleEarth" />
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-4">
        <div class="col-md-6 d-flex flex-column justify-content-start">
          <div class="text">
            <Lottie :options="textOptions" v-on:animCreated="handleText" />
          </div>

          <b-progress
            :value="loadingPercent"
            :max="max"
            class="loading-bar mt-5"
          ></b-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Plugins
import Lottie from "@/components/plugins/Lottie.vue";

// Animations
import * as earth from "../../../public/assets/animations/earth.json";
import * as text from "../../../public/assets/animations/text.json";

export default {
  name: "LoadingScreen",
  props: ["isLoading"],
  components: {
    Lottie,
  },
  data() {
    return {
      earthOptions: {
        animationData: earth.default,
        loop: true, // Optional
        autoplay: true, // Optional
      },
      textOptions: {
        animationData: text.default,
        loop: true, // Optional
        autoplay: true, // Optional
      },
      max: 100,
      loadingPercent: 0,
      loadTime: 0,
      interval: null,
      completed: false,
    };
  },
  created() {
    document.body.classList.add("overflow-hidden");

    let perfData = window.PerformanceNavigationTiming;
    let estimatedTime = Math.abs(
      perfData.loadEventEnd - perfData.navigationStart
    );
    this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100;
    this.doProgress();
  },
  methods: {
    doProgress() {
      let step = this.loadTime / 100;
      this.interval = setInterval(() => {
        this.loadingPercent++;
      }, step);
    },
    handleEarth(anim) {
      this.earth = anim;
    },
    handleText(anim) {
      this.text = anim;
    },
  },
  computed: {
    loaded() {
      return this.loadingPercent + "%";
    },
  },
  watch: {
    loadingPercent(val) {
      if (val >= 100) {
        document.body.classList.remove("overflow-hidden");

        document
          .querySelector(".loading-screen .container")
          .classList.add("no-active");

        setTimeout(() => {
          this.completed = true;
          clearInterval(this.interval);
        }, 250);

        setTimeout(() => {
          this.earth.destroy();
          this.text.destroy();
        }, 2000);
      }
    },
  },
};
</script>