import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Default",
    component: () => import("@/containers/Default"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import("@/views/Profile.vue"),
      },
      {
        path: "/education",
        name: "Education",
        component: () => import("@/views/Education.vue"),
      },
      {
        path: "/figures",
        name: "Figures",
        component: () => import("@/views/Figures.vue"),
      },
      {
        path: "/constellations",
        name: "Constellations",
        component: () => import("@/views/Constellations.vue"),
      },
      {
        path: "/sky-objects",
        name: "SkyObjects",
        component: () => import("@/views/SkyObjects.vue"),
      },
      {
        path: "/space-ships",
        name: "SpaceShips",
        component: () => import("@/views/SpaceShips.vue"),
      },
      {
        path: "/sign-in",
        name: "SignIn",
        component: () => import("@/views/SignIn.vue"),
      },
      {
        path: "/resources",
        name: "Resources",
        component: () => import("@/views/Resources.vue"),
      },
      {
        path: "/admin",
        redirect: "admin/dashboard",
        name: "Admin",
        component: () => import("@/containers/Admin"),
        beforeEnter(to, from, next) {
          if (store.state.auth.user) {
            next();
          } else {
            store.dispatch("auth/user").then(() => {
              if (store.state.auth.user) next();
              else
                next({
                  name: "SignIn",
                });
            });
          }
        },
        children: [
          {
            path: "dashboard",
            name: "Dashboard",
            component: () => import("@/views/admin/Dashboard.vue"),
          },
          {
            path: "quotes",
            name: "AdminQuotes",
            component: () => import("@/views/admin/quotes/Index.vue"),
          },
          {
            path: "quotes/create",
            name: "AdminQuotesCreate",
            component: () => import("@/views/admin/quotes/Create.vue"),
          },
          {
            path: "quotes/:id",
            name: "AdminQuotesEdit",
            component: () => import("@/views/admin/quotes/Edit.vue"),
          },
          {
            path: "figures",
            name: "AdminFigures",
            component: () => import("@/views/admin/figures/Index.vue"),
          },
          {
            path: "figures/create",
            name: "AdminFiguresCreate",
            component: () => import("@/views/admin/figures/Create.vue"),
          },
          {
            path: "figures/:id",
            name: "AdminFiguresEdit",
            component: () => import("@/views/admin/figures/Edit.vue"),
          },
          {
            path: "constellations",
            name: "AdminConstellations",
            component: () => import("@/views/admin/constellations/Index.vue"),
          },
          {
            path: "constellations/create",
            name: "AdminConstellationsCreate",
            component: () => import("@/views/admin/constellations/Create.vue"),
          },
          {
            path: "constellations/:id",
            name: "AdminConstellationsEdit",
            component: () => import("@/views/admin/constellations/Edit.vue"),
          },
          {
            path: "sky-objects",
            name: "AdminSkyObjects",
            component: () => import("@/views/admin/sky-objects/Index.vue"),
          },
          {
            path: "sky-objects/create",
            name: "AdminSkyObjectsCreate",
            component: () => import("@/views/admin/sky-objects/Create.vue"),
          },
          {
            path: "sky-objects/:id",
            name: "AdminSkyObjectsEdit",
            component: () => import("@/views/admin/sky-objects/Edit.vue"),
          },
          {
            path: "space-ships",
            name: "AdminSpaceShips",
            component: () => import("@/views/admin/space-ships/Index.vue"),
          },
          {
            path: "space-ships/create",
            name: "AdminSpaceShipsCreate",
            component: () => import("@/views/admin/space-ships/Create.vue"),
          },
          {
            path: "space-ships/:id",
            name: "AdminSpaceShipsEdit",
            component: () => import("@/views/admin/space-ships/Edit.vue"),
          },
        ],
      },
      {
        path: "*",
        name: "NotFound",
        component: () => import("@/views/NotFound.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

export default router;
