import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "./registerServiceWorker";
import store from "./store";
import AOS from "aos";
import "aos/dist/aos.css";

// Vue
import VueMeta from "vue-meta";
import VueLazyload from "vue-lazyload";
import VuePageTransition from "vue-page-transition";
import Toasted from "vue-toasted";
import VueSweetalert2 from "vue-sweetalert2";
import VueNumber from "vue-number-animation";

import "sweetalert2/dist/sweetalert2.min.css";

// Bootstrap
import {
  ModalPlugin,
  BSpinner,
  BSkeleton,
  BSkeletonImg,
  BProgress,
  BTable,
  BFormFile,
} from "bootstrap-vue";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import "./assets/scss/main.scss";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Vue
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueLazyload);
Vue.use(VuePageTransition);
Vue.use(Toasted);
Vue.use(VueSweetalert2);
Vue.use(VueNumber);

// Bootstrap
Vue.use(ModalPlugin);
Vue.component("b-spinner", BSpinner);
Vue.component("b-skeleton", BSkeleton);
Vue.component("b-skeleton-img", BSkeletonImg);
Vue.component("b-progress", BProgress);
Vue.component("b-table", BTable);
Vue.component("b-form-file", BFormFile);

// Font Awesome
Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faInstagram,
  faFacebookF,
  faTwitter,
  faWhatsapp
);

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
