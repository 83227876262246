const state = () => ({
  figure: false,
  constellation: null,
  skyObject: null,
});

export const mutations = {
  SET_TUTORIAL(state, object) {
    state[object.key] = object.value;
  },
};

export const actions = {
  setTutorial({ commit }, object) {
    commit("SET_TUTORIAL", object);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
