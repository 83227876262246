import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import tutorial from "./modules/tutorial";

Vue.use(Vuex);

const debug = process.env.VUE_APP_NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    tutorial,
  },
  strict: debug,
});
