import axios from "axios";

const state = () => ({
  user: null,
  error: {},
});

export const getters = {
  getToken: () => {
    return localStorage.getItem("token");
  },
};

export const mutations = {
  SET_USER(state, value) {
    state.user = value;
  },

  SET_TOKEN(_, value) {
    if (value) {
      localStorage.setItem("token", value);
    } else {
      localStorage.removeItem("token");
    }
  },

  SET_ERROR(state, value) {
    state.error = value;
  },
};

export const actions = {
  async login({ commit }, data) {
    await axios
      .post("users/login", data)
      .then((response) => {
        const data = response.data.body.data;
        commit("SET_USER", data.user);
        commit("SET_TOKEN", data.token);
      })
      .catch((error) => {
        commit("SET_ERROR", error.response.data.body);
      });
  },

  async logout({ commit }) {
    commit("SET_USER", null);
    commit("SET_TOKEN", null);
  },

  async user({ commit, getters }) {
    await axios
      .get("users/verify", {
        headers: { token: getters.getToken },
      })
      .then((response) => {
        const data = response.data.body.data;
        commit("SET_USER", data.user);
      })
      .catch(() => {
        commit("SET_USER", null);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
