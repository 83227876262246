<template>
  <div id="app">
    <LoadingScreen />
    <router-view />
  </div>
</template>

<script>
// Components
import LoadingScreen from "@/components/components/LoadingScreen";

export default {
  name: "App",
  components: {
    LoadingScreen,
  },
};
</script>
